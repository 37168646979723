import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import appLogo from 'images/AppLogo_New.svg'
import { AppDownloadButton } from 'modules/pet-found/public-report/AppDownloadButton';
import playStore from 'images/playstore.svg'
import appStore from 'images/appstore.svg'

const AppDownloadCard = () => {

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '100vh', backgroundColor: '#f4f4f4' }}
    >
      <Card
        sx={{
          maxWidth: 400,
          width: '100%',
          textAlign: 'center',
          padding: 3,
          borderRadius: '12px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#ffffff',
        }}
      >
        <CardContent>
          
          <img
            src={appLogo} 
            alt="App Logo"
            style={{ width: '120px', marginBottom: '16px', marginLeft: 'auto', marginRight: 'auto', color: '#EC168E' }}
          />
          <Typography variant="h3" gutterBottom>
            Noah's Ark
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Connect to pet social media with Lost pet recovery assitance, Shelters and Adoption. Download the app now.
          </Typography>
          <div className="flex justify-center">
            <AppDownloadButton
              icon={playStore}
              description="Download on the"
              platform="AppStore"
              link="https://www.apple.com/in/app-store"
            />
            <AppDownloadButton
              icon={appStore}
              description="GET IT ON"
              platform="Google play"
              link="https://play.google.com/store/games?hl=en-IN"
            />
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AppDownloadCard;
