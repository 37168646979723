import React, { useContext, useEffect, useState } from 'react'
import { TextField, Button} from '@mui/material'
import { SnackbarContext } from 'components/snackbar/SnackbarContext'
import { withFirebaseAuth } from './components/withFirebaseAuth'
import { restClient } from 'client/rest.client'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from "react-redux";
import { AuthActions } from 'store/modules/authentication/actions'
import { VerifyOTP } from './VerifyOTP'

const initialValues = {
  email: "",
  isFirstTime: false,
  firstName: '',
  lastName: ''
};

const AUTH_ERROR = "First name and last name is required for sign-in first time."

const formSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("Email is required"),
  isFirstTime: yup.boolean(),
  firstName: yup.string().when('isFirstTime', {
    is: true,
    then: () => yup.string().required('First Name is required'),
    otherwise: () => yup.string(),
  }),
  lastName: yup.string().when('isFirstTime', {
    is: true,
    then: () => yup.string().required('Last Name is required'),
    otherwise: () => yup.string(),
  }),
});

export const EmailAuthentication = withFirebaseAuth((props) => {

  const { setSeverity, showToast } = useContext(SnackbarContext)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [otpSent, setOtpSent] = useState(false)

  const onSubmit = async (data) => {
    const {email, firstName, lastName, isFirstTime} = data
    const payload = isFirstTime ? {email, firstName, lastName} : {email}
    setLoading(true)
    restClient.service('authentication/email')
    .create({ ...payload })
      .then((result) => {
        dispatch(AuthActions.setCredential({email}))
        setSeverity('success')
        showToast(result.message)
        setFieldValue('isFirstTime', false)
        setLoading(false)
        setOtpSent(true)
      })
      .catch(error => {
        setLoading(false)
        if (AUTH_ERROR === error.message) {
          dispatch(AuthActions.setCredential({email}))
          setSeverity('success')
          showToast(AUTH_ERROR)
          setFieldValue('isFirstTime', true)
        }
      })
  }

  useEffect(() => {
    props.handleLoginOption(!otpSent)
  }, [otpSent])

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    onSubmit: onSubmit,
    initialValues,
    validationSchema: formSchema,
  });

  return otpSent ? <VerifyOTP setOtpSent={setOtpSent} email={values.email}/> :
    <div className="w-full mt-8">
      <form onSubmit={handleSubmit}>
        <TextField
          id="email"
          label="Email"
          name="email"
          value={values.email || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
          margin="normal"
          fullWidth
        />
        { values.isFirstTime &&
          <div className='w-full'>
            <TextField
              id="firstName"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName || ""}
              placeholder="First name"
              label="First Name"
              className='!mb-3 !w-full'
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              fullwidth
            />

            <TextField
              id="lastName"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName || ""}
              placeholder="Last name"
              label="Last Name"
              className='!mb-3 !w-full'
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              fullwidth
            />
          </div>
        }
        <Button type="submit" size="large" variant="contained" color='primary' disabled={loading} loading={loading} fullWidth>
          Sign In
        </Button>
      </form>
    </div>

})
