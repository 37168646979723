import React, {useContext, useEffect, useState} from 'react'
import {TextField, Button, Autocomplete, Box, CircularProgress, Typography} from '@mui/material'
import {countries} from './CountryCode'
import {signInWithPhoneNumber} from 'firebase/auth'
import {auth} from '../../firebase/auth'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {withFirebaseAuth} from './components/withFirebaseAuth'
import {NumberTextField} from 'components/NumberTextField'
import {OTPInput} from './OTPInput'
import {useGeoCurrentLocation} from 'hooks/useGeoCurrentLocation'
import axios from 'axios'
import { LoadingDialog } from 'components/LodingDialog'

export const PhoneAuthentication = withFirebaseAuth(props => {
  const {onSuccessfulAuth, handleLoginOption} = props

  const {getCurrentLocation} = useGeoCurrentLocation()
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const [isOTPSent, setIsOTPSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [otp, setOTP] = useState('')
  const [prefix, setPrefix] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [confirmationResult, setConfirmationResult] = useState(null)
  const [countryCode, setCountryCode] = useState(null)
  const [loadingDialog, setLoadingDialog]  = useState(false)

  useEffect(() => {
    getCurrentLocation()
      .then(location => {
        console.log(location)
        if (location.length === 2) fetchCountryCodeByCoordinate(location[1], location[0])
      })
      .catch(e => {
        console.log(e)
        setSeverity('error')
        showToast(e)
      })
  }, [])

  const fetchCountryCodeByCoordinate = (lat, lng) =>{
    setLoadingDialog(true)
    axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}longitude=${lng}&localityLanguage=en`)
      .then(result => {
        if (result.status !== 200) return

        if (result?.data?.countryCode) {
          const country = countries.find(i => i?.code === result?.data?.countryCode)
          if (country) {
            setCountryCode(country)
            setPrefix(country?.phone)
          }
        }
        setLoadingDialog(false)
      })
      .catch((e) => {
        console.log(e)
        setLoadingDialog(false)
      })

  }

  const handlePhoneNumberChange = event => {
    setPhoneNumber(event.target.value)
  }

  const requestAuthentication = async () => {
    if (phoneNumber && prefix) {
      setLoading(true)
      signInWithPhoneNumber(auth, `${prefix}${phoneNumber}`, window.recaptchaVerifier)
        .then(confirmationResult => {
          // Save the confirmation result to be used during code verification
          setConfirmationResult(confirmationResult)
          setLoading(false)
          setIsOTPSent(true)
        })
        .catch(error => {
          console.error('Error sending verification code:', error)
          setSeverity('error')
          showToast('Could not send verification code to phone number, Please try again.')
          setLoading(false)
        })
    }
  }

  const handleVerifyCode = async () => {
    if (confirmationResult) {
      confirmationResult
        .confirm(otp)
        .then(result => {
          onSuccessfulAuth()
        })
        .catch(error => {
          setSeverity('error')
          showToast('OTP verification failed, Please try after some time.')
        })
    }
  }

  const handleCountryCodeChange = (event, value) => {
    setCountryCode(value)
    setPrefix(value?.phone)
  }

  useEffect(() => {
    handleLoginOption(!isOTPSent)
  }, [isOTPSent])

  return (
    <div className="w-full">
      {isOTPSent ? (
        <div className='mt-8'>
          <Typography variant="h6" component="h6" pt={{xs: 3, sm: 3, md: 2}} pb={3} mx="auto">
            {prefix} {phoneNumber}
          </Typography>
          <OTPInput onChange={code => setOTP(code)} className="mb-3" />
          <Button
            id="sign-in-button"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            onClick={handleVerifyCode}
          >
            {loading ? <CircularProgress style={{color: 'white'}} size="1rem" /> : 'Verify OTP'}
          </Button>
          <div className="mx-auto cursor-pointer text-blue-400 my-4" onClick={() => setIsOTPSent(false)}>Change Phone Number</div>
        </div>
      ) : (
        <div>
          <div className="w-full flex mb-3 mt-8">
            <Autocomplete
              sx={{width: 140, pl: 0}}
              options={countries}
              autoHighlight
              value={countryCode}
              getOptionLabel={option => option.label}
              onChange={handleCountryCodeChange}
              renderOption={(props, option) => (
                <Box component="li" sx={{'& > img': {mr: 1, flexShrink: 0}}} {...props}>
                  <img
                    loading="lazy"
                    width="18"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  <span className='text-xs'>{option.label} {option.phone}</span>
                </Box>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Country',
                    autoComplete: 'new-password' // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <NumberTextField
              className="flex-1"
              label="Phone Number"
              type="number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{marginLeft: '8px'}}
              onInput={event => {
                event.target.value = event.target.value.replace(/^-/, '')
              }}
              InputProps={{
                startAdornment: <span>{prefix}</span>
              }}
              inputProps={{ inputMode: 'numeric' }}
              disabled={prefix ? false : true}
            />
          </div>
          <Button variant="contained" color="primary" fullWidth size="large" onClick={requestAuthentication}>
            {loading ? <CircularProgress style={{color: 'white'}} size="1rem" /> : 'Send OTP'}
          </Button>
        </div>
      )}

      {loadingDialog && <LoadingDialog />}

      <div id="recaptcha-container"></div>
    </div>
  )
})
