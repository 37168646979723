import {auth} from '../../../firebase/auth'
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {AuthActions} from 'store/modules/authentication/actions'
import {useContext, useMemo} from 'react'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {isString} from 'lodash'

export const withFirebaseAuth = Component => {
  return props => {
    const {setSeverity, showToast} = useContext(SnackbarContext)
    const searchParams = new URLSearchParams(window?.location?.search)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const scannedCodePath = ['pet-found?code', 'pets/create', 'scan-report/create']
    const isScannedPath = path => scannedCodePath.some(ele => path.includes(ele))

    const isCodeScanned = useMemo(() => {
      return location?.state?.from && isString(location?.state?.from) && isScannedPath(location?.state?.from)
        ? true
        : false
    }, [location])

    const onSuccess = (user) => {
      const referral = searchParams?.get('referral') || null
      if (referral) {
        dispatch(AuthActions.resetAuth())
        navigate('/download', {replace: true})
        return
      }

      if (isCodeScanned) {
        if (!user?.firstName || !user?.lastName || !user.email) {
          navigate('/basic-detail', {replace: true, state: {from : location?.state?.from}})
        } else {
          navigate(location?.state?.from, {replace: true})
        }
      } else if (user?.firstName || user?.lastName) {
        navigate('/wallet', {replace: true})
      } else {
        navigate('/basic-detail', {replace: true})
      }
    }

    const onError =  e => {
      setSeverity('error')
      showToast(e)
    }

    const onSuccessfulAuth = async () => {
      try {
        const referral = searchParams?.get('referral') || null
        const token = await auth.currentUser.getIdToken()
        dispatch(
          AuthActions.login({
            token,
            onSuccess,
            onError, 
            referral
          })
        )
      } catch (e) {
        console.error(`Could not authenticate with provider`, e)
      }
    }
    return <Component onSuccessfulAuth={onSuccessfulAuth} {...props} />
  }
}
