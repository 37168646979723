// import { LoadingDialog } from "components/LodingDialog"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router";

import { LoadingDialog } from "components/LodingDialog";

export const Referral = () => {
  const params = useParams();
  const navigate = useNavigate()  
  useEffect(() => {
    if (!params?.code) return
    window.location = `noahsark://referral/${params.code}`

    setTimeout(() => {
      navigate(`/authentication/login?referral=${params.code}`)
    }, 3000)

  }, [params])

  return (<LoadingDialog />)
}
