import {Typography} from '@mui/material'
import {MediaCarousel} from './MediaCarousel'
import {useEffect, useState} from 'react'
import Card from '@mui/material/Card'
import useWindowSize from 'hooks/useWindowSize'

export const PetBehaviourDetail = props => {
  const {data, showMore} = props
  const [commandResponsive, setCommandResponsive] = useState(null)
  const [nameResponsive, setNameResponsive] = useState(null)
  const [favoriteTreat, setFavoriteTreat] = useState(null)
  const [posterURL, setPosterURL] = useState(null)
  const screenWidth = useWindowSize()
  const isMobile = screenWidth < 770

  useEffect(() => {
    if (data?.report?.poster?.url) setPosterURL(data?.report?.poster?.url)

    if (data?.report?.questionnaire?.length) {
      const questions = data?.report?.questionnaire
      const responsiveToCommands = questions.find(item => item.question === 'responsiveToCommands')
      if (responsiveToCommands) {
        responsiveToCommands['question'] = 'Is Responsive To Commands?'
        if (responsiveToCommands.answer === 'yes') {
          const commandForResponse = questions.find(item => item.question === 'commandForResponse')
          responsiveToCommands['command'] = commandForResponse.answer
        }
        setCommandResponsive(responsiveToCommands)
      }
      const responsiveToName = questions.find(item => item.question === 'responsiveToName')
      if (responsiveToName) {
        responsiveToName['question'] = 'Is Responsive To Name?'
        if (responsiveToName.answer === 'yes') {
          const nameToResponse = questions.find(item => item.question === 'nameToResponse')
          responsiveToName['command'] = nameToResponse.answer
        }
        setNameResponsive(responsiveToName)
      }
      const favoriteTreatObj = questions.find(item => item.question === 'favoriteTreat')
      if (favoriteTreatObj) {
        favoriteTreatObj['question'] = 'Favorite Treat(Food)'
        setFavoriteTreat(favoriteTreatObj)
      }
    }
  }, [data])

  return (
    <div className="flex flex-col lg:flex-row my-4">
      <div className="w-full lg:w-1/2 flex flex-col items-start">
        {isMobile && posterURL && (
          <div className="w-full mb-8 lg:mt-0 flex justify-center block md:hidden">
            <img src={posterURL} alt="lost pet poster" className="w-72 object-cover rounded-xl" />
          </div>
        )}
        <Typography className="!font-bold" align="left">
          Pet Behaviors & Warnings as Described by Owner
        </Typography>

        <div className="pr-4 w-full">
          {commandResponsive && (
            <Card variant="outlined" className="!p-3 !my-2 w-full  !text-left">
              <p className="text-sm">{commandResponsive?.question}</p>
              <p className="text-sm text-left">
                <span className="font-bold">
                  {commandResponsive?.command
                    ? `${commandResponsive?.answer} by, `
                    : commandResponsive.answer}
                </span>{' '}
                {commandResponsive?.command ? `${commandResponsive.command}` : ''}
              </p>
            </Card>
          )}

          {nameResponsive && (
            <Card variant="outlined" className="!p-3 !my-2 w-full !text-left">
              <p className="text-sm">{nameResponsive?.question}</p>
              <p className="text-sm text-left">
                <span className="font-bold">
                  {nameResponsive?.command ? `${nameResponsive.answer} by, ` : nameResponsive.answer}
                </span>{' '}
                {nameResponsive?.command ? `${nameResponsive.command}` : ''}
              </p>
            </Card>
          )}

          {favoriteTreat && (
            <Card variant="outlined" className="!p-3 !my-2 w-full !text-left">
              <p className="text-sm">{favoriteTreat?.question}</p>
              <p className="text-sm ">
                <span className="font-bold">{favoriteTreat.answer}</span>
              </p>
            </Card>
          )}
        </div>
      </div>

      <div className="w-full lg:w-1/2 mt-16 lg:mt-0">
        {showMore ? (
          <MediaCarousel media={data?.report?.media} />
        ) : !isMobile && posterURL ? (
          <div className="w-full mb-8 lg:mt-0 flex justify-center">
            <img src={posterURL} alt="lost pet poster" className="w-72 object-cover rounded-xl" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
