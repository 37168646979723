export const AppDownloadButton = props => {
  const handleClick = () => {
    window.open(props?.link, '_blank')
  }
  return (
    <div
      className="border border-gray-400 rounded-lg class flex justify-between items-center bg-black px-1 md:px-2 mx-1 cursor-pointer"
      onClick={handleClick}
    >
      <img src={props.icon} width={28} height={28} className="mx-1" />
      <div className="text-white pr-2 py-1">
        <p style={{fontSize: '10px'}}>{props?.description}</p>
        <p style={{fontSize: '1rem'}}>{props?.platform}</p>
      </div>
    </div>
  )
}
